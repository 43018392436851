/* Variables */

:root {
  --titleImageUrl: url("../images/Hero.jpg");
  --gridHeight: 250px;
  --shadow: 0 3px 10px rgba(104, 104, 104, 0.3);
  --gridColor: #fde2e4;
}

/* Main Styles */

.address {
  background-color: var(--gridColor);
  /* border: 3px solid #f1f1f1; */
  padding-top: 10%;
  text-align: center;
  font-size: 1.2rem;
  height: var(--gridHeight);
  box-shadow: var(--shadow);
}

.gridText {
  background-color: var(--gridColor);
  text-align: center;
  font-size: 1.2rem;
  padding: 2%;
  margin-top: 0.5rem;
  box-shadow: var(--shadow);
}

.gridImage {
  box-shadow: var(--shadow);
  width: 100%;
}

.titleText {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  transform: translate(0%, -110%);
  left: 0;
  right: 0;
  margin: auto;
  padding: 1rem;
  max-width: fit-content;
  text-align: center;
}

.titleHeading {
  font-size: 5rem;
  font-family: "Tangerine";
}

.titleSubHeading {
  font-size: 1.75rem;
  font-family: "Red Hat Display";
}

.flexItem {
  width: 5rem;
  height: auto;
  margin: 1rem;
}

.flexItem img:hover {
  -webkit-transform: rotateZ(-10deg);
  -ms-transform: rotateZ(-10deg);
  transform: rotateZ(-10deg);
  transition: 0.5s ease;
}

/* Title, Home Page */

.titleImage {
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(3px);
  background-image: var(--titleImageUrl);
}

.navStyle {
  background-color: #fcd5ce;
  box-shadow: var(--shadow);
}

/* Tablet */
@media (min-width: 770px) and (max-width: 1000px) {
  .titleHeading {
    font-size: 4rem;
  }
  .titleSubHeading {
    font-size: 1.2rem;
  }

  .titleImage {
    height: 200px;
  }

  .merch {
    width: 50%;
  }
}

/* Phone */
@media (max-width: 770px) {
  .container {
    width: 100vw;
  }

  .titleHeading {
    font-size: 2.5rem;
  }
  .titleSubHeading {
    font-size: 1.1rem;
  }
  .titleText {
    padding: 5px;
  }

  .titleImage {
    height: 170px;
  }

  .merch {
    width: 65%;
  }

  .form {
    width: 100%;
  }
}
